import React from "react";
import { Global } from "@emotion/react";
import { getAssetUrl } from "pwa-kit-react-sdk/ssr/universal/utils";

const Fonts = () => (
  <Global
    styles={`
    /* vietnamese */
    @font-face {
      font-family: 'Nimbus';
      font-style: normal;
      font-weight: 400;
      src: url(${getAssetUrl(
        "static/fonts/NimbusSanNovT-Regu.woff"
      )}) format('woff');
      unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }
    /* latin-ext */
    @font-face {
      font-family: 'Nimbus';
      font-style: normal;
      font-weight: 400;
      src: url(${getAssetUrl(
        "static/fonts/NimbusSanNovT-Regu.woff"
      )}) format('woff');
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
      font-family: 'Nimbus';
      font-style: normal;
      font-weight: 400;
      src: url(${getAssetUrl(
        "static/fonts/NimbusSanNovT-Regu.woff"
      )}) format('woff');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin */
    @font-face {
      font-family:"Nimbus-Bold";
      font-style: normal;
      font-weight: 400;
      src: url(${getAssetUrl(
        "static/fonts/NimbusSanNovT-Bold.ttf"
      )}) format("opentype"), url(${getAssetUrl(
      "static/fonts/NimbusSanNovT-Bold.ttf"
    )}) format("truetype");
    }
    /* latin */
    @font-face {
      font-family: 'Gotham Book';
      font-style: normal;
      font-weight: 400;
      src: local('Gotham Book'), url('https://fonts.cdnfonts.com/s/13925/GOTHAMBOOK.woff') format('woff');
    }
    /* latin */
    @font-face {
      font-family: 'Gotham Bold';
      font-style: normal;
      font-weight: 400;
      src: local('Gotham Bold'), url('https://fonts.cdnfonts.com/s/14807/Gotham Bold.woff') format('woff');
    }
    /* latin */
    @font-face {
      font-family:"homepagebaukasten-book";
      font-style: normal;
      font-weight: 400;
      src: url(${getAssetUrl(
        "static/fonts/HomepageBaukasten-Book.ttf"
      )}) format("opentype"), url(${getAssetUrl(
      "static/fonts/HomepageBaukasten-Book.ttf"
    )}) format("truetype");
    }
      `}
  />
);

export default Fonts;
